import app from "../firebase/FirebaseAppService";

export default async function signInWithEmailPassword(email, password) {
    try {
        return await app
            .auth()
            .signInWithEmailAndPassword(email, password).then(() => {
                return {
                    status: "success"
                };
            });
    } catch (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode + " " + errorMessage);
        return {
            status: "failure"
        };
    }
}