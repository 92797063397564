import { useState, useEffect } from "react";
import getTermsConditionsAndPolicy from "../../../services/terms&conditions-policy/index";

export default function TermsandConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState("");

  useEffect(() => {
    createMarkup();
  }, []);

  async function createMarkup() {
    const termsAndConditions = await getTermsConditionsAndPolicy();
    const formattedTnc =
      termsAndConditions.docData.termsAndConditions.replaceAll("\n", "<br/>");
    setTermsAndConditions(formattedTnc);
  }

  function test() {
    return {
      __html: termsAndConditions,
    };
  }

  return (
    <>
      <h2
        style={{ textAlign: "center", fontFamily: '"Gill Sans", sans-serif' }}
      >
        Terms and Conditions
      </h2>{" "}
      <div style={{ margin: "0 20%" }} dangerouslySetInnerHTML={test()} />
    </>
  );
}
