import {
  Badge,
  Button,
  Drawer,
  Fab,
  IconButton,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import signOut from "../../services/auth/SignOutService";
import Cookies from "universal-cookie";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useState } from "react";
import getAllNotifications, {
  clearNotification,
} from "../../services/notifications";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
  title: {
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
  },
}));

export default function SignOut() {
  const classes = useStyles();

  const [notifications, setNotifications] = useState([]);

  async function handleClick(e) {
    e.preventDefault();
    const SignOut = await signOut();
  }

  useEffect(() => {
    async function fetch() {
      const notifications = await getAllNotifications();
      console.log(notifications);
      if (notifications.status === "success") {
        setNotifications(notifications.docs);
      } else {
        setNotifications([]);
      }
    }
    fetch();
  }, []);

  console.log(notifications);

  async function handleCancelNotification(docId, docRef) {
    console.log(docRef);
    const cancelNotification = await clearNotification(docRef);
    if (cancelNotification.status === "success") {
      console.log("success");
      const filteredResult = notifications.filter(
        (notification) => notification.docId !== docId
      );
      setNotifications(filteredResult);
    } else {
      setNotifications([]);
    }
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Typography variant="h6" className={classes.title}>
          Notifications <NotificationsIcon style={{ marginTop: "2%" }} />
        </Typography>
        <Divider style={{ marginTop: "2%" }} />
        {notifications
          ? notifications.map((notification, index) => (
              <ListItem button key={index}>
                <ListItemText
                  primary={`${notification.docData.notification.title}`}
                  secondary={notification.docData.notification.body}
                />
                <ListItemSecondaryAction
                  size="small"
                  onClick={(e) =>
                    handleCancelNotification(
                      notification.docId,
                      notification.docRef
                    )
                  }
                >
                  <IconButton edge="end" aria-label="delete" size="small">
                    <ClearIcon size="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : ""}
      </List>
    </div>
  );

  console.log(notifications);
  return (
    <>
      <Button
        color="inherit"
        onClick={toggleDrawer("right", true)}
        size="small"
      >
        <Badge
          color="primary"
          badgeContent={notifications ? notifications.length : 0}
        >
          <NotificationsIcon />
        </Badge>
      </Button>
      <Button color="inherit" onClick={handleClick}>
        <ExitToAppIcon fontSize="small" style={{ marginRight: "8px" }} />
        Sign Out
      </Button>

      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </>
  );
}
