import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import signUpWithEmailPassword from "../../services/auth/SignUpService";
import { Link } from "react-router-dom";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import GlassCard from "../../utils/glassCard";
import validator from "validator";
import _ from "lodash";
import { Backdrop, FormHelperText } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgressBar from "../support/progress/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${process.env.REACT_APP_MAIN_IMG_URL})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    backgroundColor: "#1976d2",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  field: {
    marginBottom: "2%",
  },
}));

function SignUp(props) {
  const classes = useStyles();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  function handleEmailChange(e) {
    e.preventDefault();
    setEmail(e.target.value);
    validateEmail(e);
  }

  function handlePhoneNumberChnage(e) {
    e.preventDefault();
    setPhoneNumber(e.target.value);
    validatePhone(e);
  }

  function handlePasswordChange(e) {
    e.preventDefault();
    setPassword(e.target.value);
    validatePassword(e);
  }

  function handleFnameChange(e) {
    e.preventDefault();
    setFname(e.target.value);
    validateFname(e);
  }

  function handleLnameChnage(e) {
    e.preventDefault();
    setLname(e.target.value);
    validateLname(e);
  }
  function handleConfirmPasswordChange(e) {
    e.preventDefault();
    setConfirmPassword(e.target.value);
    validateConfirmPassword(e);
  }

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  const handleClickOpenSnackBar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  async function handleClick(e) {
    e.preventDefault();
    handleBackDropOpen();
    signUpWithEmailPassword(email, password, fname, lname, phoneNumber)
      .then((result) => {
        if (result.status === "success") {
          setFname("");
          setLname("");
          setEmail("");
          setPassword("");
          setPhoneNumber("");
          handleBackDropClose();
          setSnackBarMsg("SignUp successfully");
          setSnackBarSeverity("success");
          handleClickOpenSnackBar();
        } else {
          handleBackDropClose();
          setSnackBarMsg("Unable to SignUp");
          setSnackBarSeverity("error");
          handleClickOpenSnackBar();
        }
      })
      .catch((error) => {
        handleBackDropClose();
        setSnackBarMsg("Unable to SignUp");
        setSnackBarSeverity("error");
        handleClickOpenSnackBar();
      });
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (e) => {
    e.preventDefault();
  };

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [fnameError, setFnameError] = useState("");
  const [lnameError, setLnameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [btndisabled, setBtndisabled] = useState(true);
  const [checked, setChecked] = useState(false);

  const validateFname = (e) => {
    var fname = e.target.value;
    if (!fname.match(/^[A-Za-z]+$/) || _.isEmpty(fname)) {
      setFnameError("Enter valid Characters !");
      setBtndisabled(true);
    } else {
      setFnameError("");
    }
  };

  const validateLname = (e) => {
    var lname = e.target.value;
    if (!lname.match(/^[A-Za-z]+$/) || _.isEmpty(lname)) {
      setLnameError("Enter valid Characters !");
      setBtndisabled(true);
    } else {
      setLnameError("");
    }
  };

  const validatePhone = (e) => {
    var phoneNumber = e.target.value;
    if (
      _.isEmpty(phoneNumber) ||
      phoneNumber.length < 10 ||
      phoneNumber.length > 13
    ) {
      setPhoneError("Enter valid Phone Number!");
      setBtndisabled(true);
    } else {
      setPhoneError("");
    }
  };

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
      setBtndisabled(true);
    }
  };

  const validatePassword = (e) => {
    var password = e.target.value;
    if (
      password.match(/[!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ||
      _.isEmpty(password)
    ) {
      setPasswordError("Enter valid Password !");
      setBtndisabled(true);
    } else {
      setPasswordError("");
    }
  };

  const validateConfirmPassword = (e) => {
    var confirmPassword = e.target.value;
    if (password !== confirmPassword) {
      setConfirmPasswordError(
        "The confirm password confirmation does not match"
      );
      setBtndisabled(true);
    } else {
      setConfirmPasswordError("");
    }
  };

  function handleCheckChange(e) {
    let checkbox = e.target.checked;
    setChecked(checkbox);
    console.log(checked);
    if (checked === true) {
      setBtndisabled(true);
    } else {
    }
  }

  useEffect(() => {
    if (
      _.isEmpty(fnameError) &&
      _.isEmpty(lnameError) &&
      _.isEmpty(passwordError) &&
      _.isEmpty(emailError) &&
      _.isEmpty(confirmPasswordError) &&
      !_.isEmpty(fname) &&
      !_.isEmpty(lname) &&
      validator.isEmail(email) &&
      !_.isEmpty(password) &&
      !_.isEmpty(confirmPassword) &&
      checked === true
    ) {
      setBtndisabled(false);
    }
  }, [
    fname,
    lname,
    checked,
    email,
    password,
    fnameError,
    lnameError,
    passwordError,
    emailError,
    confirmPasswordError,
    confirmPassword,
  ]);

  useEffect(() => {
    if (props.user.uid) {
      props.history.push("/");
    }
  }, [props.history, props.user]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  function handleBackDropClose() {
    setOpenBackdrop(false);
  }

  function handleBackDropOpen() {
    setOpenBackdrop(true);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {process.env.REACT_APP_NAME}
          </Typography>
          <Typography component="h2" variant="h6">
            Sign Up
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.field}
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={fname}
              onChange={handleFnameChange}
              helperText={fnameError}
              error={fnameError}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              size="small"
            />{" "}
            <TextField
              className={classes.field}
              variant="outlined"
              required
              fullWidth
              id="lastName"
              value={lname}
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              onChange={handleLnameChnage}
              helperText={lnameError}
              error={lnameError}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              size="small"
            />{" "}
            <TextField
              className={classes.field}
              variant="outlined"
              required
              fullWidth
              type="number"
              id="phoneNumber"
              value={phoneNumber}
              label="Phone Number"
              name="phoneNumber"
              autoComplete="phone number"
              onChange={handlePhoneNumberChnage}
              helperText={phoneError}
              error={phoneError}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              size="small"
            />{" "}
            <TextField
              className={classes.field}
              variant="outlined"
              required
              fullWidth
              id="email1"
              label="Email Address"
              value={email}
              name="email"
              autoComplete="email"
              onChange={handleEmailChange}
              helperText={emailError}
              error={emailError}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              size="small"
            />{" "}
            <FormControl
              variant="outlined"
              fullWidth
              helperText={passwordError}
              className={classes.field}
              size="small"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                error={passwordError}
              >
                Password
              </InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={passwordError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText error id="accountId-error">
                {passwordError}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              helperText={confirmPasswordError}
              className={classes.field}
              size="small"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                error={confirmPasswordError}
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                label="Confirm Password"
                required
                id="outlined-adornment-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={confirmPasswordError}
                helperText={confirmPasswordError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              <FormHelperText error id="accountId-error">
                {confirmPasswordError}
              </FormHelperText>
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              checked={checked}
              onChange={handleCheckChange}
              label={
                <label>
                  I have read the{" "}
                  <Link
                    to="/termsandconditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1976d2" }}
                  >
                    terms and conditions{" "}
                  </Link>
                  and
                  <Link
                    to="/policies"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1976d2" }}
                  >
                    {" "}
                    the privacy policy
                  </Link>
                </label>
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleClick}
              disabled={btndisabled}
            >
              Sign Up{" "}
            </Button>{" "}
            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  variant="body2"
                  to="/signin"
                  style={{ textDecoration: "none", color: "#1976d2" }}
                >
                  Already have an account ? Sign in{" "}
                </Link>{" "}
              </Grid>{" "}
            </Grid>{" "}
          </form>
        </div>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgressBar display={true} />
      </Backdrop>
    </Grid>
  );
}

const mapStateToProps = (state) => state.authReducer;
export default connect(mapStateToProps)(SignUp);
