import firebase from "firebase";
import dotenv from "dotenv";
import store from "../../redux/store/store";
import { userSignedOut, userSignedIn, authCheckDone, setAuthClaims } from "../../redux/actions/auth";
dotenv.config()

// Initialize Firebase App
const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STRORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(process.env.REACT_APP_APP_CHECK_PUBLIC_KEY);

firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
        firebase.auth().currentUser.getIdTokenResult()
            .then((idTokenResult) => {
                console.log(idTokenResult);
                if (!!idTokenResult.claims.user) {
                    console.log("userSignedIn");
                    store.dispatch(userSignedIn(user))
                    store.dispatch(setAuthClaims("user"));
                    store.dispatch(authCheckDone())
                    const messaging = app.messaging();
                        messaging.getToken({ vapidKey: process.env.REACT_APP_FCM_PUBLIC_KEY }).then(async(token)=>{
                        console.log("Token: " + token); 
                        const docRef = app.firestore().collection("Users").doc(app.auth().currentUser.uid);
                        try {
                            await docRef.set({
                            fcm:{token:token}
                            },{merge:true})
                        } catch (error) {
                            console.log(error)
                        }
                        }).catch((err)=>{
                        console.log("Error: " + err);
                        }
                    );
                } else {
                    console.log("userSignedOut");
                    store.dispatch(userSignedOut())
                    store.dispatch(setAuthClaims(""));
                    store.dispatch(authCheckDone())
                }
            })
            .catch((error) => {
                console.log(error);
                store.dispatch(userSignedOut())
                store.dispatch(setAuthClaims(""));
                store.dispatch(authCheckDone())
            });
    } else {
        console.log("userSignedOut");
        store.dispatch(userSignedOut())
        store.dispatch(setAuthClaims(""));
        store.dispatch(authCheckDone())
    }
});

export default app;