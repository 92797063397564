import app from "../firebase/FirebaseAppService";
import signInWithEmailPassword from "./SignInService";

export default async function signUpWithEmailPassword(email, password, fname, lname, phoneNumber) {
    try {
        const getUserAccess = app.functions().httpsCallable('getUserAccess');
        return await getUserAccess({
            firstName: fname,
            lastName: lname,
            email: email,
            password: password,
            userType: 'End User',
            phoneNumber: phoneNumber
        }).then(async(data) => {
            return await signInWithEmailPassword(email, password).then((result) => {
                console.log(result);
                return {
                    status: "success",
                };
            });
        });
    } catch (error) {
        console.log(error);
        return {
            status: "failure",
        };
    }
}